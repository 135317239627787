Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'bx_block_advanced_search/search'
exports.errorTitle = 'Error'
exports.storageToken="authToken"

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkLoggedInUser } from "../../CustomisableUserProfiles/src/utility.web";

// Customizable Area Start
import {checkLoggedInUserSettings} from "./utility.web"
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/CommonFunction";
type ErrorProductFormData = {
  productName?: string;
  productDescription?: string;
};

type ErrorProductPriceFormData = {
  cost?: string;
  markUp?: string;
  unitPrice?: string;
};

type ErrorServiceFormData = {
  serviceName?: string;
  serviceDescription?: string;
};

type ErrorServicePriceFormData = {
  serviceCost?: string;
  serviceMarkUp?: string;
};

interface Image {
  id?: string | number;
  file?: File | Blob;
  url?: string;
  name?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productsDialogOpen: boolean;
  productImages: any;
  deletedProductImages: any;
  productFormData: {
    productName: string;
    productDescription: string;
  },
  productPriceFormData: {
    unitPrice: string;
    markUp: string;
    totalPrice: string;
  },
  productExemptTax: boolean;
  isErrorProductFormData: ErrorProductFormData;
  isErrorProductPriceFormData: ErrorProductPriceFormData;
  serviceFormData: {
    serviceName: string;
    serviceDescription: string;
  },
  servicePriceFormData: {
    serviceCost: string;
    serviceMarkUp: string;
    serviceTotalPrice: string;
  },
  serviceExemptTax: any,
  isErrorServiceFormData: ErrorServiceFormData;
  isErrorServicePriceFormData: ErrorServicePriceFormData;
  servicesDialogOpen: boolean;
  serviceImages: any;
  deletedServiceImages: any;
  viewProducts: boolean;
  viewServices: boolean;
  getCurrency:string;
  selectedProduct: any;
  selectedService: any;
  productsData: any;
  servicesData: any;
  deleteProductsDialogOpen: boolean;
  deleteServicesDialogOpen: boolean;
  isEditProduct: boolean;
  productName: string;
  isEditService: boolean;
  serviceName: string;
  serviceProgress: number;
  serviceCompleted: boolean;
  isUploadedServices: boolean;
  productProgress: number;
  productCompleted: boolean;
  isUploadedProducts: boolean;
  createProductsLoading: boolean;
  getAllProductsLoading: boolean;
  createServicesLoading: boolean;
  getAllServicesLoading: boolean;
  deleteProductsLoading: boolean;
  deleteServicesLoading: boolean;
  viewProductLoading: boolean;
  viewServiceLoading: boolean;
  updateProductLoading: boolean;
  updateServiceLoading: boolean;
  isEmployee:boolean
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProductAndServicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createProductsApiCallId: string = "";
  getAllProductsApiCallId: string = "";
  createServicesApiCallId: string = "";
  getAllServicesApiCallId: string = "";
  viewProductApiCallId: string = "";
  deleteProductsApiCallId: string = "";
  viewServicesApiCallId: string = "";
  deleteServicesApiCallId: string = "";
  updateProductApiCallId: string = "";
  updateServiceApiCallId: string = "";
  getCurrencySymApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      productsDialogOpen: false,
      productImages: [],
      deletedProductImages: [],
      productFormData: {
        productName: "",
        productDescription: "",
      },
      productPriceFormData: {
        unitPrice: "",
        markUp: "",
        totalPrice: "0.00",
      },
      getCurrency:'',
      productExemptTax: false,
      isErrorProductFormData: {},
      isErrorProductPriceFormData: {},
      serviceFormData: {
        serviceName: "",
        serviceDescription: "",
      },
      servicePriceFormData: {
        serviceCost: "",
        serviceMarkUp: "",
        serviceTotalPrice: "0.00"
      },
      serviceExemptTax: false,
      isErrorServiceFormData: {},
      isErrorServicePriceFormData: {},
      servicesDialogOpen: false,
      serviceImages: [],
      deletedServiceImages: [],
      viewProducts: true,
      viewServices: true,
      selectedProduct: null,
      selectedService: null,
      productsData: [],
      servicesData: [],
      deleteProductsDialogOpen: false,
      deleteServicesDialogOpen: false,
      isEditProduct: false,
      productName: "",
      isEditService: false,
      serviceName: "",
      serviceProgress: 0,
      serviceCompleted: false,
      isUploadedServices: false,
      productProgress: 0,
      productCompleted: false,
      isUploadedProducts: false,
      createProductsLoading: false,
      getAllProductsLoading: false,
      createServicesLoading: false,
      getAllServicesLoading: false,
      deleteProductsLoading: false,
      deleteServicesLoading: false,
      viewProductLoading: false,
      viewServiceLoading: false,
      updateProductLoading: false,
      updateServiceLoading: false,
      isEmployee:false,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    
    const user =await checkLoggedInUserSettings()
    this.setState({isEmployee:user})
    this.getAllProducts();
    this.setState({ getAllProductsLoading: true });
    this.getAllServices();
    this.setState({ getAllServicesLoading: true });
    this.getCurrencySym()
    // Customizable Area End
  }

  // Customizable Area Start
  tokenExpired = (isEmployee: boolean) => {
    setTimeout(this.setTimeOutHandler.bind(this, isEmployee), 2000);
  }

  setTimeOutHandler = (isEmployee: boolean) => {
    localStorage.clear()
    this.props.navigation.history?.push(isEmployee ? "/Employee/Login" : "/Login");
  }

  errorHandlerMessage = async (error: { token: string }) => {
    const { token } = error
    if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel)
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  errorResponseMessage = (error: { token: string }) => {
    error && this.errorHandlerMessage(error);
  }
  
  extractDataFromMessage = (message: Message) => {
    return {
      apiRequestCallId: message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),
      responseJson: message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      errorResponse: message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
    };
  };

  getError = (responseJson: any) => {
    return responseJson?.errors?.[0];
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createProductsApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            createProductsLoading: false,
            productsDialogOpen: false,
          });
          this.props.openToastHandler(t('settings.productCreateSuccess'), "success");
          this.getAllProducts();
          this.handleCloseProductsDialog();
        } else if (responseJson.errors) {
          this.setState({
            createProductsLoading: false,
            productsDialogOpen: false,
          });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ createProductsLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    this.handleGetAllProducts(message);
    this.handleCreateServices(message);
    this.handleGetAllServices(message);
    this.handleViewProduct(message);
    this.handleDeleteProducts(message);
    this.handleViewService(message);
    this.handleDeleteServices(message);
    this.handleUpdateProduct(message);
    this.handleUpdateService(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetAllProducts = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllProductsApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getAllProductsLoading: false });
          this.setState({ productsData: responseJson.data });
        } else if (responseJson.errors) {
          this.setState({ getAllProductsLoading: false });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ getAllProductsLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }else if(apiRequestCallId === this.getCurrencySymApiCallId){
        this.getCurrencySymApiResponse(responseJson)
      }
    }
  }
  getCurrencySymApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ getCurrency:responseJson.currency_code })
    } else {
      if (responseJson&&responseJson.errors) {
        const error = this.getError(responseJson);
          this.errorResponseMessage(error);
      }
    }
  }
  handleCreateServices = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createServicesApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            createServicesLoading: false,
            servicesDialogOpen: false,
          });
          this.props.openToastHandler(t('settings.serviceCreateSuccess'), "success");
          this.getAllServices();
          this.handleCloseServicesDialog();
        } else if (responseJson.errors) {
          this.setState({
            createServicesLoading: false,
            servicesDialogOpen: false,
          });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ createServicesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  handleGetAllServices = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllServicesApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getAllServicesLoading: false });
          this.setState({ servicesData: responseJson.data });
        } else if (responseJson.errors) {
          this.setState({ getAllServicesLoading: false });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ getAllServicesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleViewProduct = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.viewProductApiCallId) {
        if (!responseJson.errors) {
          this.setState({ viewProductLoading: false });
          const {
            name,
            description,
            exempt_tax,
            unit_price,
            markup,
            total_price,
            images
          } = responseJson?.data?.attributes;
          const editProductFormData = {
            productName: name,
            productDescription: description,
          }
          const editProductPriceFormData = {
            unitPrice: unit_price,
            markUp: markup,
            totalPrice: total_price,
          }
          this.setState({
            productFormData: editProductFormData,
            productPriceFormData: editProductPriceFormData,
            productExemptTax: exempt_tax,
            productImages: [...images || ""]
          });
        } else if (responseJson.errors) {
          this.setState({ viewProductLoading: false });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ viewProductLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeleteProducts = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteProductsApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            deleteProductsLoading: false,
            deleteProductsDialogOpen: false,
          });
          this.props.openToastHandler(t('settings.productDeleteSuccess'), "success");
          this.getAllProducts();
        } else if (responseJson.errors) {
          this.setState({
            deleteProductsLoading: false,
            deleteProductsDialogOpen: false,
          });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ deleteProductsLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleViewService = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.viewServicesApiCallId) {
        if (!responseJson.errors) {
          this.setState({ viewServiceLoading: false });
          const {
            name,
            description,
            exempt_tax,
            cost,
            markup,
            total_price,
            images
          } = responseJson?.data?.attributes;
          const editServiceFormData = {
            serviceName: name,
            serviceDescription: description,
          }
          const editServicePriceFormData = {
            serviceCost: cost,
            serviceMarkUp: markup,
            serviceTotalPrice: total_price
          }
          this.setState({
            serviceFormData: editServiceFormData,
            servicePriceFormData: editServicePriceFormData,
            serviceExemptTax: exempt_tax,
            serviceImages: [...images || ""]
          });
        } else if (responseJson.errors) {
          this.setState({ viewServiceLoading: false });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ viewServiceLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeleteServices = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteServicesApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            deleteServicesLoading: false,
            deleteServicesDialogOpen: false,
          });
          this.props.openToastHandler(t('settings.serviceDeleteSuccess'), "success");
          this.getAllServices();
        } else if (responseJson.errors) {
          this.setState({
            deleteServicesLoading: false,
            deleteServicesDialogOpen: false,
          });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ deleteServicesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleUpdateProduct = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.updateProductApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            updateProductLoading: false,
            productsDialogOpen: false,
            selectedProduct: null,
            deletedProductImages: []
          });
          this.handleCloseProductsDialog();
          this.props.openToastHandler(t('settings.productUpdateSuccess'), "success");
          this.getAllProducts();
        } else if (responseJson.errors) {
          this.setState({
            updateProductLoading: false,
            productsDialogOpen: false,
          });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ updateProductLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleUpdateService = (message: Message) => {
    const { t } = this.props;
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.updateServiceApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            updateServiceLoading: false,
            servicesDialogOpen: false,
            selectedService: null,
            deletedServiceImages: []
          });
          this.handleCloseServicesDialog();
          this.props.openToastHandler(t('settings.serviceUpdateSuccess'), "success");
          this.getAllServices();
        } else if (responseJson.errors) {
          this.setState({
            updateServiceLoading: false,
            servicesDialogOpen: false,
          });
          const error = this.getError(responseJson);
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ updateServiceLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  handleViewAllProducts = () => {
    this.setState({ viewProducts: !this.state.viewProducts });
  }
  
  handleViewAllServices = () => {
    this.setState({ viewServices: !this.state.viewServices });
  }
  
  handleAddProducts = async () => {
    await removeStorageData(configJSON.product_id);
    this.setState({
      productsDialogOpen: true,
      viewProducts: true,
    });
  }

  handleSelectProduct = (id: string, name: string, ) => {
    this.setState({ selectedProduct: id }, async () => {
      if(this.state.isEmployee) {
        await this.handleEditProduct(name, id);
      }
    });
  }

  handleEditProduct = async (name: string, id: string) => {
    await setStorageData(configJSON.product_id, id);
    if(this.state.selectedProduct) {
      this.viewProduct();
      this.setState({ viewProductLoading: true });
    }
    this.setState({
      isEditProduct: true,
      productName: name,
      productsDialogOpen: true,
    });
  }

  handleEditService = async (name: string, id: string) => {
    await setStorageData(configJSON.service_id, id);
    if(this.state.selectedService) {
      this.viewService();
      this.setState({ viewServiceLoading: true });
    }
    this.setState({
      isEditService: true,
      serviceName: name,
      servicesDialogOpen: true,
    });
  }

  handleSelectService = (id: string, name: string) => {
    this.setState({ selectedService: id }, async () => {
      if(this.state.isEmployee) {
        await this.handleEditService(name, id);
      }
    });
  }

  handleCloseProductsDeleteDialog = () => {
    this.setState({ deleteProductsDialogOpen: false });
  }

  handleDeleteProduct = () => {
    this.setState({ deleteProductsDialogOpen: true });
  }

  handleClickProceedDeleteProducts = () => {
    this.deleteProduct();
    this.setState({ deleteProductsLoading: true });
  }

  handleCloseProductsDialog = async () => {
    await removeStorageData(configJSON.product_id);
    this.setState({
      productsDialogOpen: false,
      isEditProduct: false,
      productFormData: { ...this.state.productFormData, productName: "", productDescription: "" },
      productPriceFormData: { ...this.state.productPriceFormData, unitPrice: "", markUp: "", totalPrice: "0.00" },
      productExemptTax: false,
      productImages: [],
      productProgress: 0,
      productCompleted: false,
      isErrorProductFormData: {},
      isErrorProductPriceFormData: {},
      deletedProductImages: []
    });
    const redirectUrl = await getStorageData("cancelRedirection");
    this.redirect(redirectUrl);
  }
  redirect = (url: string) => {
    if (url) {
      setTimeout(this.clearRedirectUrl, 2000);
      this.props.navigation.history?.push(url);
    } else {
      setTimeout(this.clearRedirectUrl, 2000);
    }
  };

  clearRedirectUrl = async () => {
    await removeStorageData("cancelRedirection");
  };
  handleCloseServicesDeleteDialog = () => {
    this.setState({ deleteServicesDialogOpen: false });
  }

  handleDeleteService = () => {
    this.setState({ deleteServicesDialogOpen: true });
  }

  handleClickProceedDeleteService = () => {
    this.deleteService();
    this.setState({ deleteServicesLoading: true });
  }
  
  handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      productFormData: {
        ...this.state.productFormData, [e?.target.name]: e?.target.value
      }
    });
  }

  validateProductFormData = (values: ErrorProductFormData) => {
    const errors: ErrorProductFormData = {};

    const { t } = this.props;
    if (!values.productName) {
      errors.productName = t('settings.productNameRequired');
    } else if (values.productName.length > 23) {
      errors.productName = t('settings.productNameLength');
    }

    if (values.productDescription && values.productDescription.length > 500) {
      errors.productDescription = t('settings.productNameDesc');
    }
    
    return errors;
  }

  handleProductPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      productPriceFormData: {
        ...this.state.productPriceFormData, [e?.target.name]: e?.target.value
      }
    }, () => {
      this.calculateProductTotal();
    });
  }

  calculateProductTotal = () => {
    const { unitPrice, markUp } = this.state.productPriceFormData;
    const parsedUnitPrice = parseFloat(unitPrice);
    const parsedMarkUp = parseFloat(markUp);
  
    if (!isNaN(parsedUnitPrice)) {
      const subTotal = (parsedUnitPrice * parsedMarkUp || 0) / 100;
      const total = parsedUnitPrice + subTotal;
  
      this.setState({
        productPriceFormData: {
          ...this.state.productPriceFormData,
          totalPrice: total.toFixed(2),
        },
      });
  
      return total.toFixed(2);
    }
    return null;
  }

  validateProductPriceFormData = (values: ErrorProductPriceFormData) => {
    const errors: ErrorProductPriceFormData = {};
    const regexDecimals = /^\s*\d+(\.\d+)?\s*$/;

    const { t } = this.props;
    if (!values.unitPrice) {
      errors.unitPrice = t('settings.unitPriceRequired');
    } else if (values.unitPrice && !regexDecimals.test(values.unitPrice)) {
      errors.unitPrice = t('settings.numericValues');
    }
    
    if (values.markUp && !regexDecimals.test(values.markUp)) {
      errors.markUp = t('settings.numericValues');
    } else if (values.markUp && parseFloat(values.markUp) > 100) {
      errors.markUp = t('settings.markupLength');
    } else if (values.markUp && !/^\d+(\.\d{1,2})?$/.test(values.markUp)) {
      errors.markUp = t('settings.markupLengthDecimal');
    }

    return errors;
  }
  
  handleCheckProductExemptTax = () => {
    this.setState({ productExemptTax: !this.state.productExemptTax });
  }
  
  handleSelectProductFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event?.target.files;
    if (files && files.length > 0) {
      const newProductImages = Array.from(files);
      const duplicates = newProductImages.filter(file =>
        this.state.productImages.some((existingFile: File) => existingFile.name === file.name)
      );
  
      if (duplicates.length > 0) {
        const filteredFiles = newProductImages.filter(file =>
          !duplicates.some(duplicate => duplicate.name === file.name)
        );
        newProductImages.length = 0;
        newProductImages.push(...filteredFiles);
      }
  
      this.setState(prevState => ({
        productImages: [...prevState.productImages, ...newProductImages],
        isUploadedProducts: prevState.isUploadedProducts || duplicates.length === 0
      }), () => {
        this.handleSelectProductImages(this.state.productImages);
      });
    }
    if(this.state.productImages.length === 0) {
      this.setState({
        productCompleted: false,
        productProgress: 0
      });
    }
  }

  handleSelectProductImages = (files: FileList) => {
    if (Array.isArray(files) || files instanceof FileList) {
      const totalFiles = files.length;
      let uploadedFiles = 0;
      for (const file of files) {
        const reader = new FileReader();
        reader.onloadstart = () => {
          this.setState({ productProgress: 0 });
        };
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            this.setState({ productProgress: percentComplete });
          }
        };
        reader.onload = (e: ProgressEvent<FileReader>) => {
          uploadedFiles++;
          const progress = Math.round((uploadedFiles / totalFiles) * 100);
          this.setState({ productProgress: progress });
          if (uploadedFiles === totalFiles && progress === 100) {
            setTimeout(() => {
              this.setState({ isUploadedProducts: false });
            }, 3000);
          }
        };

        reader.onerror = (event: any) => {
          console.error("File could not be read! Code " + event.target.error.code);
        };
        const blob = new Blob([file], { type: file.type });
        reader.readAsDataURL(blob);
      }
    }
  }

  handleDeleteProductsImage = (item: Image) => {
    const { id, name } = item;
    const { productImages, deletedProductImages } = this.state;
  
    const updatedImages = productImages?.filter((image: Image) => !(image?.name === name && image?.id === id));
    
    if (updatedImages.length !== productImages?.length) {
      deletedProductImages.push(id);
    }
  
    this.setState({ productImages: updatedImages });
  }

  handleDeleteServicesImage = (item: Image) => {
    const { id, name } = item;
    const { serviceImages, deletedServiceImages } = this.state;
  
    const updatedImages = serviceImages?.filter((image: Image) => {
      return !(image?.name === name && image?.id === id)
    });
    
    if (updatedImages.length !== serviceImages?.length) {
      deletedServiceImages.push(id);
    }
  
    this.setState({ serviceImages: updatedImages });
  }

  handleSaveProductsDialog = async () => {
    this.setState({ isErrorProductFormData: this.validateProductFormData(this.state.productFormData), isErrorProductPriceFormData: this.validateProductPriceFormData(this.state.productPriceFormData) },
      async () => {
        if (Object.keys(this.state.isErrorProductFormData).length === 0 && Object.keys(this.state.isErrorProductPriceFormData).length === 0) {
          this.createProducts();
          this.setState({ createProductsLoading: true });
          const redirectUrl = await getStorageData("cancelRedirection");
          this.redirect(redirectUrl);
        }
      }
    );
  }

  handleUpdateProductsDialog = () => {
    this.setState({ isErrorProductFormData: this.validateProductFormData(this.state.productFormData), isErrorProductPriceFormData: this.validateProductPriceFormData(this.state.productPriceFormData)  },
      () => {
        if(Object.keys(this.state.isErrorProductFormData).length === 0 && Object.keys(this.state.isErrorProductPriceFormData).length === 0) {
          this.updateProduct();
          this.setState({ updateProductLoading: true });
        }
      }
    );
  }
  
  handleAddServices = async () => {
    await removeStorageData(configJSON.service_id);
    this.setState({
      servicesDialogOpen: true,
      viewServices: true,
    });
  }

  handleCloseServicesDialog = async () => {
    await removeStorageData(configJSON.service_id);
    this.setState({
      servicesDialogOpen: false,
      isEditService: false,
      serviceFormData: { ...this.state.serviceFormData, serviceName: "", serviceDescription: "" },
      servicePriceFormData: { ...this.state.servicePriceFormData, serviceCost: "", serviceMarkUp: "", serviceTotalPrice: "0.00" },
      serviceExemptTax: false,
      serviceImages: [],
      serviceProgress: 0,
      serviceCompleted: false,
      isErrorServiceFormData: {},
      isErrorServicePriceFormData: {},
      deletedServiceImages: []
    });
    const redirectUrl = await getStorageData("cancelRedirection");
    this.redirect(redirectUrl);
  }
  
  handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      serviceFormData: { ...this.state.serviceFormData, [e?.target.name]: e?.target.value }
    });
  }

  validateServiceFormData = (values: ErrorServiceFormData) => {
    const errors: ErrorServiceFormData = {};

    const { t } = this.props;
    if (!values.serviceName) {
      errors.serviceName = t('settings.serviceNameRequired');
    } else if (values.serviceName.length > 23) {
      errors.serviceName = t('settings.serviceNameLength');
    }

    if (values.serviceDescription && values.serviceDescription.length > 500) {
      errors.serviceDescription = t('settings.serviceDescLength');
    }
    
    return errors;
  }

  handleServicePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      servicePriceFormData: {
        ...this.state.servicePriceFormData, [e?.target.name]: e?.target.value
      }
    }, () => {
      this.calculateServiceTotal();
    });
  }

  calculateServiceTotal = () => {
    const { serviceCost, serviceMarkUp } = this.state.servicePriceFormData;
    const parsedCost = parseFloat(serviceCost);
    const parsedMarkUp = parseFloat(serviceMarkUp);
  
    if (!isNaN(parsedCost)) {
      const subTotal = (parsedCost * parsedMarkUp || 0) / 100;
      const total = parsedCost + subTotal;
  
      this.setState({
        servicePriceFormData: {
          ...this.state.servicePriceFormData, serviceTotalPrice: total.toFixed(2)
        },
      });
  
      return total.toFixed(2);
    }
    return null;
  }

  validateServicePriceFormData = (values: ErrorServicePriceFormData) => {
    const errors: ErrorServicePriceFormData = {};
    const regexDecimals = /^\s*\d+(\.\d+)?\s*$/;

    const { t } = this.props;
    if (!values.serviceCost) {
      errors.serviceCost = t('settings.costRequired');
    } else if (!regexDecimals.test(values.serviceCost)) {
      errors.serviceCost = t('settings.numericValues');
    }

    if (values.serviceMarkUp && !regexDecimals.test(values.serviceMarkUp)) {
      errors.serviceMarkUp = t('settings.numericValues');
    } else if (values.serviceMarkUp && parseFloat(values.serviceMarkUp) > 100) {
      errors.serviceMarkUp = t('settings.markupLength');
    } else if (values.serviceMarkUp && !/^\d+(\.\d{1,2})?$/.test(values.serviceMarkUp)) {
      errors.serviceMarkUp = t('settings.markupLengthDecimal');
    }

    return errors;
  }

  handleCheckServiceExemptTax = () => {
    this.setState({ serviceExemptTax: !this.state.serviceExemptTax });
  }

  handleSelectServicesFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event?.target.files;
    if (files && files.length > 0) {
      const newServiceImages = Array.from(files);
      const duplicates = newServiceImages.filter(file =>
        this.state.serviceImages.some((existingFile: Image) => existingFile.name === file.name)
      );
  
      if (duplicates.length > 0) {
        const filteredFiles = newServiceImages.filter(file =>
          !duplicates.some(duplicate => duplicate.name === file.name)
        );
        newServiceImages.length = 0;
        newServiceImages.push(...filteredFiles);
      }
  
      this.setState(prevState => ({
        serviceImages: [...prevState.serviceImages, ...newServiceImages],
        isUploadedServices: prevState.isUploadedServices || duplicates.length === 0
      }), () => {
        this.handleSelectServicesImages(this.state.serviceImages);
      });
    }
    if(this.state.serviceImages.length === 0) {
      this.setState({
        serviceCompleted: false,
        serviceProgress: 0
      });
    }
  }

  handleSelectServicesImages = (files: FileList) => {
    if (Array.isArray(files) || files instanceof FileList) {
      const totalFiles = files.length;
      let uploadedFiles = 0;
      for (const file of files) {
        const reader = new FileReader();
        reader.onloadstart = () => {
          this.setState({ serviceProgress: 0 });
        };
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            this.setState({ serviceProgress: percentComplete });
          }
        };
        reader.onload = (e: ProgressEvent<FileReader>) => {
          uploadedFiles++;
          const progress = Math.round((uploadedFiles / totalFiles) * 100);
          this.setState({ serviceProgress: progress });
          if (uploadedFiles === totalFiles && progress === 100) {
            setTimeout(() => {
              this.setState({ isUploadedServices: false });
            }, 3000);
          }
        }

        reader.onerror = (event: any) => {
          console.error("File could not be read! Code " + event.target.error.code);
        };
        const blob = new Blob([file], { type: file.type });
        reader.readAsDataURL(blob);
      }
    }
  }
  
  handleSaveServicesDialog = async () => {
    this.setState({ isErrorServiceFormData: this.validateServiceFormData(this.state.serviceFormData), isErrorServicePriceFormData: this.validateServicePriceFormData(this.state.servicePriceFormData)  },
      async () => {
        if(Object.keys(this.state.isErrorServiceFormData).length === 0 && Object.keys(this.state.isErrorServicePriceFormData).length === 0 && this.state.serviceFormData.serviceDescription.length <= 200) {
          this.createServices();
          this.setState({ createServicesLoading: true });
          const redirectUrl = await getStorageData("cancelRedirection");
          this.redirect(redirectUrl);
        }
      }
    );
  }

  handleUpdateServicesDialog = () => {
    this.setState({ isErrorServiceFormData: this.validateServiceFormData(this.state.serviceFormData), isErrorServicePriceFormData: this.validateServicePriceFormData(this.state.servicePriceFormData)  },
      () => {
        if(Object.keys(this.state.isErrorServiceFormData).length === 0 && Object.keys(this.state.isErrorServicePriceFormData).length === 0 && this.state.serviceFormData.serviceDescription.length <= 200) {
          this.updateService();
          this.setState({ updateServiceLoading: true });
        }
      }
    );
  }

  createProducts = async () => {
    const { productName, productDescription } = this.state.productFormData;
    const { markUp, unitPrice, totalPrice } = this.state.productPriceFormData;
    const { productImages, productExemptTax } = this.state;
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[name]", productName?.trim());
    formdata.append("data[description]", productDescription?.trim());
    formdata.append("data[unit_price]", String(unitPrice)?.trim());
    formdata.append("data[markup]", String(markUp)?.trim());
    formdata.append("data[total_price]", totalPrice);
    formdata.append("data[exempt_tax]", productExemptTax as unknown as Blob);

    for(let image of productImages) {
      formdata.append("data[images][]", image);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createProductsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productsAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAllProducts = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllProductsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productsAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getCurrencySym = async () => {
    const token = await getStorageData(configJSON.authToken)
    const header = {
      "token": token
    };
    const getCurrencyApiCall = apiCall({
      header: header,
      httpBody: null,
      url: configJSON.getCurrency,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.getCurrencySymApiCallId = getCurrencyApiCall.messageId;
    runEngine.sendMessage(getCurrencyApiCall.id, getCurrencyApiCall);
  }
  createServices = async () => {
    const { serviceName, serviceDescription } = this.state.serviceFormData;
    const { serviceCost, serviceMarkUp, serviceTotalPrice } = this.state.servicePriceFormData;
    const { serviceImages, serviceExemptTax } = this.state;
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[name]", serviceName?.trim());
    formdata.append("data[description]", serviceDescription?.trim());
    formdata.append("data[cost]", String(serviceCost)?.trim());
    formdata.append("data[markup]", String(serviceMarkUp)?.trim());
    formdata.append("data[total_price]", serviceTotalPrice);
    formdata.append("data[exempt_tax]", serviceExemptTax);

    for(let image of serviceImages) {
      formdata.append("data[images][]", image);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.servicesAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAllServices = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.servicesAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  viewProduct = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productsAPIEndPoint}/${this.state.selectedProduct}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteProduct = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProductsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productsAPIEndPoint}/${this.state.selectedProduct}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  viewService = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.servicesAPIEndPoint}/${this.state.selectedService}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteService = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.servicesAPIEndPoint}/${this.state.selectedService}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  updateProduct = async () => {
    const { productName, productDescription } = this.state.productFormData;
    const { markUp, unitPrice, totalPrice } = this.state.productPriceFormData;
    const { productExemptTax, deletedProductImages } = this.state;
    const filterNewImages = this.state.productImages?.filter(({ id }: any) => !id);
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[name]", productName?.trim());
    formdata.append("data[description]", productDescription?.trim())
    formdata.append("data[unit_price]", String(unitPrice)?.trim());
    formdata.append("data[markup]", String(markUp)?.trim());
    formdata.append("data[total_price]", totalPrice);
    formdata.append("data[exempt_tax]", productExemptTax as unknown as Blob);

    for(let image of filterNewImages) {
      formdata.append("data[images][]", image);
    }

    for(let deletedImage of deletedProductImages) {
      formdata.append("data[image_id][]", deletedImage);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productsAPIEndPoint}/${this.state.selectedProduct}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  updateService = async () => {
    const { serviceName, serviceDescription } = this.state.serviceFormData;
    const { serviceCost, serviceMarkUp, serviceTotalPrice } = this.state.servicePriceFormData;
    const { serviceExemptTax, deletedServiceImages } = this.state;
    const filterNewImages = this.state.serviceImages?.filter(({ id }: any) => !id);
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[name]", serviceName?.trim());
    formdata.append("data[description]", serviceDescription?.trim())
    formdata.append("data[cost]", String(serviceCost)?.trim());
    formdata.append("data[markup]", String(serviceMarkUp)?.trim());
    formdata.append("data[total_price]", serviceTotalPrice);
    formdata.append("data[exempt_tax]", serviceExemptTax);

    for(let image of filterNewImages) {
      formdata.append("data[images][]", image);
    }

    for(let deletedImage of deletedServiceImages) {
      formdata.append("data[image_id][]", deletedImage);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateServiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.servicesAPIEndPoint}/${this.state.selectedService}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End
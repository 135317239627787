
export const CurrentLocation =(map:any)=>{
    navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Set the map's center to the current location
          map.setCenter(new google.maps.LatLng(latitude, longitude));
          map.setZoom(14);  // Optionally, set a zoom level
        },
        (error) => {
          alert("Could not fetch current location.");
        }
      );
}
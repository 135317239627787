// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import {format} from "date-fns"
import 'moment/locale/ar';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { t } from "i18next";
export const configJSON = require("./config");
import DatePicker from "react-datepicker";
import React from "react";
interface INavigateTo {
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
export interface ToggleData{
  id:number,
  title:string,
  hidden:boolean
}
interface TimesheetAttributes {
  id: number;
  account_id: number;
  associate_id: number;
  associate_type: string;
  status: string | null;
  date:string| null;
  start_time: string;
  end_time: string;
  employee_id: number;
  description: string | null;
  rate: number | null;
  billable: boolean;
  created_at: string;
  updated_at: string;
  customer_name: string;
  duration: string
}

export interface JobData {
  id?: string; 
  attributes: {
    job_title: string; custmoer_name: string; start_date: moment.MomentInput; end_date: moment.MomentInput;
}
}

export interface StatusData {
  data: JobData[] | [];
}

export type StatusObject = {
  upcoming: {
     data: JobData[] | []
  };
  in_progress: {
     data: JobData[] | []
  };
  completed: {
     data: JobData[] | []
  };
  to_be_invoiced: {
     data: JobData[] | []
  };
  late: {
     data: JobData[] | []
  };
  cancelled: {
     data: JobData[] | []
  };
}

type ErrorResponse = {
  message: string;
};

type ResponseJson = ErrorResponse | StatusObject;

export interface RequestStatusData {
  data: RequestType[]  | [];
}

export interface RequestResType {
  upcoming: {
    data: RequestType[] | []
 };
 in_progress: {
    data: RequestType[] | []
 };
 completed: {
    data: RequestType[] | []
 };
 to_be_invoiced: {
    data: RequestType[] | []
 };
 late: {
    data: RequestType[] | []
 };
 cancelled: {
    data: RequestType[] | []
}
}

export type RequestResponseJson = ErrorResponse | RequestResType;
export interface RequestType  {
  id: string;
  type: string;
  attributes: {
      request_title: string;
      account_id: number;
      employee_id: string | null;
      status: string;
      site_id: number;
      service_detail: string;
      customer_id: number;
      on_site: boolean;
      appointment_date: string;
      all_day: boolean;
      internal_notes: string;
      converted_to_job: boolean;
      converted_to_quote: boolean;
      created_at: string;
      updated_at: string;
      appointment_time: string | null;
      job_id: string | null;
      other_source: string | null; 
      source: string;
      custmoer_name: string; 
      company_logo: string | null;
      appointment_id: string | null;
      files: string | null; 
      pdf_url: string | null;
      employees: []; 
      employee_image: [];
      request_id: string;
  }
};

export interface Props {
  isTextFieldVisible: boolean,
  t: (key: string) => string;
  languageSelected: string
  openToastHandler:Function
  isPopupOpen: boolean;
  classes: ClassNameMap<string>
}
export interface JobState {
  data: Jobs[];

}
export interface Location {
  address: string | null;
  latitude: string | null;
  longitude: string | null;
  city: string | null;
  zip_code: string | null;
}
export interface JobAttributes {
  visits: { data: [] };
  job_title: string;
  employee_id: number | null;
  account_id: number;
  request_id: number | null;
  customer_id: number;
  site_id: number;
  status: string;
  custmoer_name: string;
  scheduling: string;
  duration: number | null;
  pdf_url: string | null;
  summary: string | null;
  frequency: number | null;
  every: number;
  start_date: string;
  end_date: string;
  quote: string | null;
  site_address: SiteAddress;
  created_at: string;
  internal_notes: string;
  updated_at: string;
  product_and_services: string | null;
  employee_image: [];
  employees: {name:string,img: string, mail:string}[];
  company_logo: string | null;
  location: Location;
  start_time: string;
  files: unknown | null;
  end_time: string;
  job_id: string;
  products: Products;
}
export interface Products {
  data: ProductItem[];
}
export interface ProductItem {
  attributes: ProductAttributes;
  id: string;
  type: string;
  service_name:string
  
}
export interface ProductAttributes {
  product_name: string;
  product_id: number;
  service_id: number | null;
  quantity: number;
  unit_price: string;
  currency: string;
  total: string;
  description: string;
  job_id: number;
  quote_id: number | null;
  visit_id: number | null;
}

export interface SiteAddress {
  address: string;
  latitude: string;
  longitude: string;
  city: string;
  state: string;
  zip_code: string;
}

export interface Jobs {
  id: string | number;
  type: string;
  attributes: JobAttributes;
  product?: {
    id: string;
    type: string;
    attributes: {
      product_id: number;
      service_id: number | null;
      quantity: number;
      unit_price: string;
      currency: string;
      total: string;
      description: string;
      job_id: number;
      quote_id: number | null;
      visit_id: number | null;
      product_name: string;
      service_name: string;
    };
  };
}
export interface JobsData {
  upcoming: JobState;
  in_progress: JobState;
  completed: JobState;
  to_be_invoiced: JobState;
  late: JobState;
  cancelled: JobState;
  message?:string
}
export interface AttributesArray {
  data: ValueObject[]}
export interface ValueObject{
  attributes: {
    "admin_address": [],
    request_title?:string;
    'company_logo': null,
    'created_at': Date,
    'currency': string,
    'customer_id': number,
    'discount': string,
    'customer_name': string,
    'files': null,
    'invoice_id': string,
    'invoice_deposits': [],
    'issue_date': string,
    'job_title': string,
    'job_id': string,
    'notes': string,
    'newEngStatus': string,
    'payment_due': string,
    'pdf_url': {},
    'products': [],
    'site_id': string,
    'quote_id': string,
    'sub_total': number,
    'status': string
    'tax': number,
    'tax_name': string,
    'total': number
    'title': string,
    'id': string,
    'updated_at': string,
    'type': string,
    account_id: number;
    employee_id: number | null;
    scheduling: string;
    request_id: number | null;
    frequency: number | null;
    summary: string | null;
    duration: number | null;
    every: number;
    start_date: string;
    internal_notes: string;
    end_date: string;
    product_and_services: string | null;
    quote: string | null;
    employees: { name: string, img: string, mail: string }[];
    location: Location;
    employee_image: [];
    site_address: SiteAddress;
    start_time: string;
    end_time: string;
    custmoer_name: string;
  };
  id: string;
  type: string;
  product?: ProductItem;
}

interface S {
  isTextFieldVisible: boolean,
  startTime: string | null;
  timeFormat: string,
  isPopupOpen: boolean;
  endTime:string,
  selectLanguage:string
  tabValue: number ;
  duration: string 
  timesheetDate: Date  |string,
  view: string,
  EditKey: TimesheetAttributes,
  isEditKey: boolean;
  description: string | null,
  errors: {
     startTime: string | null, endTime: string, duration:string, description: string
  }
  statusModal: boolean
  selectedDate:{
    startDate: Date | null,
    endDate: null|Date
  },
  dateModal: boolean
  dateStatus:string
  selectedStatusForAPI:string,
  searchJobs: string,
  sortColumn: string;
  sortDirection: string;
  allJobsData:Record<string,AttributesArray> | JobsData | {};
  allRequestsList: RequestResponseJson;
  checkedItems:string[];
  status: string;
  jobLoading:boolean;
  requestsLoading: boolean;
  isJOb:boolean;
  isRequest:boolean;
  selectedJob:JobData | null,
  selectedRequest:RequestType | null;
  checkedItemsRequest:string[],
  selectedDates: {
    startDate: Date,
    endDate: null | Date
  },
  selectedLanguaged: string,
  dayDuration:string,
  searchRequest:string,
  getJobsLoading:boolean,
  sortRequestManagesLoading:boolean,
  statusToggleData: { 
    id: number;
    title: string;
    statusName: string;
     hidden: boolean }[];
     checkStatusName: string,
     jobAsscociateId:string,
     customerName:string
}
export interface ProductItem {
  id: string;
  type: string;
  attributes: ProductAttributes;
  service_name:string

}
export interface JobStatusData {
  id:number,
  title:string,
  statusName:string
}

interface SS {

}

export default class TimeSheetHoursController extends BlockComponent<
  Props,
  S,
  SS
> {
  authenticateApiCallId: string = "";
  apiCallIdCreateAntries : string = "";
  updateTimesheetApiCallId: string = "";
  deleteTimesheetApiCallId: string= ""
  getJobApiCallId:string='';
  getRequestsApiCallId: string = '';
  datePickerRef: React.RefObject<DatePicker>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.datePickerRef = React.createRef(); 
    this.state = {
      isTextFieldVisible: false,
      startTime: '07:00 AM',
      timeFormat: '',
      isPopupOpen: false,
      endTime:"07:30 AM",
      selectLanguage:'',
      duration: localStorage.getItem("lang") === 'ar' ? '٠٠:٣٠':'00:30',
      timesheetDate: new Date(),
      view: '',
      description:'',
      isEditKey: false,
      EditKey:{
        id: 0,
        account_id: 0,
        associate_id: 0,
        associate_type: '',
        status: "all",
        date:  null,
        start_time:  '',
        end_time:  '',
        employee_id: 0,
        description: '',
        rate: 0,
        billable: false,
        created_at:  '',
        updated_at:  '',
        customer_name:  '',
        duration: ''
      },
      errors: {
         startTime: '', endTime: '', duration:'', description: ''
      },
      tabValue:0,
      statusModal: false,
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dateModal: false,
      dateStatus: `${t('dashboard.today')}`,
      selectedStatusForAPI:'today',
      searchJobs: "",
      sortColumn:"",
      sortDirection: "asc",
      allJobsData:{
        
      },
     
      allRequestsList: {
        "upcoming": { data: [] },
        "in_progress": { data: [] },
        "completed": { data: [] },
        "to_be_invoiced": { data: [] },
        "late": { data: [] },
        "cancelled": { data: [] },
      },
      checkedItems: ["all", "upcoming","in_progress","invoiced","completed","late","cancelled"],
      status: "all",
      jobLoading: false,
      requestsLoading: false,
      selectedJob: null,
      selectedRequest:null,
      isJOb:true,
      isRequest:false,
      checkedItemsRequest: ["all", "pending","quoted","converted_to_job","overdue","cancelled"],
      selectedDates: {
        startDate: new Date(),
        endDate: null
      },
      selectedLanguaged:'',
      dayDuration:'',
      searchRequest:"",
      getJobsLoading:false,
      sortRequestManagesLoading:false,
      statusToggleData: [
        { id: 1, title: `${this.props.t('request.statusMenu.all')}`, statusName: "all", hidden: false },
        { id: 2, title: `${this.props.t('jobs.statusMenu.upcoming')}`, statusName: "upcoming", hidden: false },
        { id: 3, title: `${this.props.t('jobs.statusMenu.inProgress')}`, statusName: "in_progress", hidden: false },
        { id: 4, title: `${this.props.t('jobs.statusMenu.invoiced')}`, statusName: "invoiced", hidden: false },
        { id: 5, title: `${this.props.t('jobs.statusMenu.completed')}`, statusName: "completed", hidden: false },
        { id: 6, title: `${this.props.t('jobs.statusMenu.late')}`, statusName: "late", hidden: false },
        { id: 7, title: `${this.props.t('request.statusMenu.cancelled')}`, statusName: "cancelled", hidden: false },
      ],
      checkStatusName: "All",
      jobAsscociateId:"931",
      customerName:""

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.apiCallIdCreateAntries) {
        this.navigateTo({props:this.props, screenName:"Timesheet"})
      }else if(apiRequestCallId === this.updateTimesheetApiCallId){
        this.handleUpdateApiResponse()
      } else if(apiRequestCallId === this.deleteTimesheetApiCallId){
        this.navigateTo({props:this.props,screenName: "Timesheet"})
      }else if(apiRequestCallId === this.getJobApiCallId){
        this.getJobResponse(responseJson)
      }else if(apiRequestCallId === this.getRequestsApiCallId){
        this.handleRequestsApiResponce(responseJson)
      }
    }

  }



  async componentDidMount() {
    await super.componentDidMount();
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectLanguage: selectedLanguage })
    this.handleLanguage(selectedLanguage)
    const selectedView = await getStorageData("view");
    this.setState({view: selectedView})

    const selectedLanguaged = await getStorageData("lang");
    this.setState({ selectedLanguaged: selectedLanguaged })

    const selectedEdit = await getStorageData("editKey");
    let editData;
    editData = typeof selectedEdit === `string` ? JSON.parse(selectedEdit): selectedEdit
    if(selectedEdit !== null){
      this.setState({isEditKey: true})
      this.EditTimesheetData(editData)
    }
    this.setState({EditKey: editData})
    NetInfo.addEventListener((state: NetInfoState) => {

    });
  }
  //istanbul ignore next
handleSelectDateStatus = (item: { title: string; }) => {
    const { t } = this.props;
    const { startDate, endDate } = this.state.selectedDates;
  this.setState({ dateStatus: item.title },() => {
    if (item.title === t('dashboard.custom')) {
      if (startDate 
        && endDate) 
        {
         this.handleJobSorting();

         this.sortRequestManage();

        this.setState({ getJobsLoading: true 

        });
      }
    } else 
    {
       this.handleJobSorting();

        this.sortRequestManage();

       this.setState({ getJobsLoading: true 

       });
    }});
  if (item.title !== t('dashboard.custom')) 
  {
    this.handleStatusModalClose()
  }}
  handleLanguage = (selectedLanguage :string) => {
    switch (selectedLanguage) {
      case "ar":
      return  moment.locale('ar'); 
        case "en":
          return  moment.locale('en'); 
      default:
        return  moment.locale('en'); 
    }
  }
  handleBackBtn = () => {
    removeStorageData("editKey");
    this.setState({isEditKey: false})
    this.navigateTo({props:this.props,screenName: "Timesheet"})
  }
  handleCancelClick = () => {
    this.state.EditKey.id ? this.handleDelete():
    this.navigateTo({props:this.props,screenName: "Timesheet"})
  };

  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
    this.setState({ isTextFieldVisible: false });
  };

  handlePopupDelete = () => {
    this.setState({ isPopupOpen: false,  });
    this.deleteTimesheet()
  };

  handleTimeChange = (newTime : string | null ) => {
    this.setState({ startTime: newTime });
  };

  handleButtonClick = () => {
    this.setState({ isTextFieldVisible: true }, () => this.handleJobSorting())};

  handleChangeHours =(timevalue:string)=>{
    this.setState(
      {
        startTime: timevalue
      }, this.calculateTotalTime
  )
  }
  
  handleChangeHoursendTime = (timevalue:string) => {
    this.setState({
      endTime:timevalue
    }, this.calculateTotalTime
  )
  }

  calculateTotalTime = () => 
    {
      const { startTime, endTime } = this.state;
      if (startTime && endTime){
        const startMinutes = this.convertTimeToMinutes(startTime);
        const endMinutes = this.convertTimeToMinutes(endTime);
        let totalMinutes = endMinutes - startMinutes;
        const totalTime = this.convertMinutesToTime(totalMinutes);
        this.setState({duration : totalTime });
      }
    }

  convertTimeToMinutes = (time: string): number => {
    const selectedLanguage = localStorage.getItem('lang')
    const normalizedTime = selectedLanguage === 'ar'
    ? time.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (digit) => '0123456789'['٠١٢٣٤٥٦٧٨٩'.indexOf(digit)])
    : time;

    const [timePart] = normalizedTime.split(' ');
    const [hoursStr, minutesStr] = timePart.split(':');
    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);
    const modifier = normalizedTime.split(' ')[1];
    let totalHours = hours;
    if (modifier === 'PM' && hours !== 12) {
        totalHours += 12;
    } else if (modifier === 'AM' && hours === 12) {
        totalHours = 0;
    }

    if (isNaN(hours) || isNaN(minutes)) { return 0 }
    return totalHours * 60 + minutes;
  };

  convertMinutesToTime = (totalMinutes: number): string => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const selectedLanguage = localStorage.getItem('lang')
    const arabicDigits = (num: number): string => {
      return num.toString().replace(/\d/g, (digit) => '٠١٢٣٤٥٦٧٨٩'[Number(digit)]);
    };
  
    const formattedHours = selectedLanguage=== 'ar' ? arabicDigits(Math.abs(hours)) : Math.abs(hours).toString().padStart(2, '0');
    const formattedMinutes = selectedLanguage=== 'ar' ? arabicDigits(Math.abs(minutes)) : Math.abs(minutes).toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  };

  handleDuration = (event : React.ChangeEvent<HTMLInputElement>) => {
   this.setState({duration : event.target?.value})
  }
  handleDescription = (event : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({description : event.target?.value})
  }

  handleTimesheetDateChange = (date: Date) => {
    this.setState({timesheetDate: date});
  }

  handleSaveTimesheet = () => {
    const {  startTime, endTime, duration ,description } = this.state;
    let errors = {
       startTime: '', endTime: '', duration:'', description:''
    }
    if (!startTime) errors.startTime = t('Calendar.ErrorMessage.startTime');
    if (!endTime) errors.endTime = t('Calendar.ErrorMessage.endTime');
    if (!duration) errors.duration = t('timesheet.errorMessage.durationError');
    if (!description) errors.description = t('timesheet.errorMessage.descriptionError');

    if (Object.values(errors).every(error => error === "")) {
      this.onCreateProject()
    } else {
      this.setState({ errors });
    }
  }
  translateToEnglishTime = (arabicTime: string) => {
    const [hour, minute, period] = arabicTime.split(/[ :]/);
    let translatedHour = String(hour);
    let translatedMinute = String(minute);
    
    const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
    const englishNumerals = "0123456789";
    
    for (let checktime = 0; checktime < arabicNumerals.length; checktime++) {
      translatedHour = translatedHour.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
      translatedMinute = translatedMinute.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
    }

    let translatedPeriod = period;
    if (period === "مساء") {
      translatedPeriod = "PM";
    } else if (period === "صباخا") {
      translatedPeriod = "AM";
    }

    if (period === "مساء" && parseInt(translatedHour, 10) < 12) {
      translatedHour = String(parseInt(translatedHour, 10) + 12);
    }

    translatedHour = translatedHour.padStart(2, '0');
    translatedMinute = translatedMinute.padStart(2, '0');

    return `${translatedHour}:${translatedMinute} ${translatedPeriod || ""}`;
  };
  onCreateProject =async () => {
   const id : string | undefined  = this.state.isJOb ? this.state.selectedJob?.id : this.state.selectedRequest?.id ;
   
      let bodyDataTimeSheet = new FormData();
      bodyDataTimeSheet.append("data[date]",new Date(this.state.timesheetDate).toISOString().slice(0, 10))
      bodyDataTimeSheet.append("data[start_time]",this.translateToEnglishTime(this.state.startTime ?? "") ?? "")
      bodyDataTimeSheet.append("data[end_time]",this.translateToEnglishTime(this.state.endTime))
      bodyDataTimeSheet.append("data[duration]",this.translateToEnglishTime(this.state.duration))
      bodyDataTimeSheet.append("data[associate_id]",id || "931")
      bodyDataTimeSheet.append("data[associate_type]",`BxBlockRequestManagement::${this.state.isJOb ? 'Job' : 'Request'}`)
      bodyDataTimeSheet.append("data[description]",this.state.description ?? "")

      const token =await getStorageData("authToken")
      
      const header = {
        token:token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallIdCreateAntries = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.timeSheetAPIEndPoint}?lang=${this.state.selectLanguage}&view=${this.state.view}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        bodyDataTimeSheet
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }

    navigateTo = ({props,screenName,raiseMessage}: INavigateTo) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      raiseMessage &&
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      runEngine.sendMessage(message.id, message);
      }
  
      EditTimesheetData = (editData : any) => {
        const {date, start_time, end_time, duration, description,customer_name}= editData
        
        this.setState({
          timesheetDate: moment.utc(date, 'YYYY-MM-DD').toDate(),
          startTime: moment.utc(start_time).format("hh:mm A"),
          endTime:moment.utc(end_time).format("hh:mm A"),
          duration: duration,
          description: description,
          customerName:customer_name
        })
      }
  /* istanbul ignore next */ 
  handleEditTimesheet = async() => {
    const token =await getStorageData("authToken")
    const id : string | undefined  = this.state.isJOb ? this.state.selectedJob?.id : this.state.selectedRequest?.id ;
      
    const header = {
      token:token
    };
 
    let updateTimeSheetBody = new FormData();
    updateTimeSheetBody.append("data[id]", this.state.EditKey.id.toString())
    updateTimeSheetBody.append("data[date]",new Date(this.state.timesheetDate).toISOString().slice(0, 10))
    updateTimeSheetBody.append("data[start_time]",this.translateToEnglishTime(this.state.startTime ?? '')?? "")
    updateTimeSheetBody.append("data[end_time]",this.translateToEnglishTime(this.state.endTime))
    updateTimeSheetBody.append("data[duration]",this.translateToEnglishTime(this.state.duration))
    updateTimeSheetBody.append("data[associate_id]",id || "931")
    updateTimeSheetBody.append("data[associate_type]",`BxBlockRequestManagement::${this.state.isJOb ? 'Job' : 'Request'}`)
    updateTimeSheetBody.append("data[description]",this.state.description ?? "")
   
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTimesheetApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.timeSheetAPIEndPoint}/${this.state.EditKey.id}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), updateTimeSheetBody
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateApiMethodtype
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleUpdateApiResponse=() => {
  this.navigateTo({props:this.props,screenName: "Timesheet"})
        removeStorageData('editKey')
      this.setState({EditKey: {
        id: 0,
        account_id: 0,
        associate_id: 0,
        associate_type: '',
        status: "",
        date:  '',
        start_time:  '',
        end_time:  '',
        employee_id: 0,
        description: '',
        rate: 0,
        billable: false,
        created_at:  '',
        updated_at:  '',
        customer_name:  '',
        duration: ''
      }, isEditKey: false})
}

handleDelete= () => {
  this.setState({isPopupOpen : true})
}

deleteTimesheet = async() => {
  const token =await getStorageData("authToken")
  const header = {
    "token": token
  };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
  this.deleteTimesheetApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.timeSheetAPIEndPoint}/${this.state.EditKey.id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  handleTabValue = (value: number) => {
    this.setState({tabValue: value, status:"all",searchJobs: '',sortColumn:'',sortDirection:'asc',dateStatus: `${t('dashboard.today')}`,
     statusModal:false})
    this.handleStatusModalClose();
    
    if(value === 1){
      this.sortRequestManage();
      this.setState({isRequest:true, isJOb:false})
    }else{
      this.setState({isRequest:false, isJOb:true})
    }
  }
  handleStatusOpen =() => {
    this.setState({statusModal: !this.state.statusModal})
  }
  handleStatusModalClose = () => {
    if (this.state.dateStatus !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }

  handleDateModalOpen = () => {
    this.setState({ dateModal: !this.state.dateModal });
  }
 //istanbul ignore next
  handleSelectStatus = (item: {value:string, title: string; }) => {
    const { startDate, endDate } = this.state.selectedDate;
    this.setState({ dateStatus: item.title,selectedStatusForAPI:item.value }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.handleJobSorting();
        }
      } else {
        this.handleJobSorting();
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }
//istanbul ignore next
  handleDateChange = (dates: [Date, null]) => {
    const [startDate, endDate] = dates;
    this.setState({
      selectedDates: { ...this.state.selectedDates, startDate: startDate, endDate: endDate },
    }, () => {
      if (startDate && endDate) {
        this.setState({ dateModal: false });
        this.handleJobSorting();
      }
    });
  };

  handleSearchJobs= (event : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchJobs : event.target?.value})
  }

  handleApiresponce = (event : React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter'){
      if(this.state.isJOb){
        this.handleJobSorting()
      }else{
        this.sortRequestManage()
      }
    }
  }

  handleSort = (columnKey: string) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (columnKey === this.state.sortColumn) {
      newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
    }
    this.setState({
      sortDirection: newSortDirection,
      sortColumn: columnKey
    }, () => {
      if(this.state.tabValue === 0){
        this.handleJobSorting()
      }else{
        this.sortRequestManage()
      }
    });
  };
  /* istanbul ignore next */
  statusAr = (status: string) => {
    let {dayDuration} =this.state
      if (status === "'DJHE") {
        dayDuration = "today";
      } else if (status === "G0' 'D'3(H9") {
        dayDuration = "this_week";
      } else if (status === "G0' 'D4G1") {
        dayDuration = "this_month";
      } else if (status === "G0' 'D9'E") {
        dayDuration = "this_year";
      } else if (status === "E.55") {
        dayDuration = "custom";
      }
      return dayDuration;
    }
    //istanbul ignore next
    handleJobSorting = async () => {
      const language = await getStorageData("lang");
      const token =await getStorageData("authToken");
      const { startDate, endDate } = this.state.selectedDates;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token };
      const apiEndPoint = configJSON.JobsEndPoint
      let jobsUrl
      if (this.state.searchJobs) 
        {
        jobsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchJobs}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
      } else if (this.state.sortColumn) 
        {
        jobsUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
      } else 
      {   
        jobsUrl = `${apiEndPoint}?status=${this.state.status}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus).toLowerCase().replace(/ /g, "_") : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
      }
      this.setState({getJobsLoading: true})
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.getJobApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), jobsUrl);
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  //istanbul ignore next
  handleSelectedStatus = (item:JobStatusData,jobStatusData:JobStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
      if (item.statusName === "all") {
        this.setState({
          checkedItems:jobStatusData.map((subItem) => subItem.statusName),
          status: "all"
        }, () => {
          this.handleJobSorting();
        })
      } else {
        this.setState({
          checkedItems: [...this.state.checkedItems,
          item.statusName
          ],
          status: [...this.state.checkedItems,
          item.statusName
          ].join(",")
        }, () => {
          this.handleJobSorting();
        })
      }
    } else {
      if (item.statusName === "all") {
        this.setState({
          checkedItems: [],
          status: ""
        }, () => {
          this.handleJobSorting();
        })
      } else {
        this.setState({
          checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
          status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
        }, () => {
          this.handleJobSorting();
        })
      }
    }
  }

  getJobResponse = (responseJson: ResponseJson) => {
    if ("message" in responseJson && responseJson.message === "you are not authorized") {
      this.setState({ jobLoading: false });
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error");
    } else {
      this.setState({ jobLoading: false });

      this.setState({ allJobsData: responseJson })


    }
  }

  handleStatusJobModalClose = () => {
    this.setState({ statusModal: false, })
  }
  //istanbul ignore next
  sortRequestManage = async () => {
    const language = await getStorageData("lang");
      const token =await getStorageData("authToken");
      const { startDate, endDate } = this.state.selectedDates;
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };
    const apiEndPoint = configJSON.getAllRequest
    let requestsUrl
    if (this.state.searchRequest&&this.state.sortColumn) 
      {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else if(this.state.searchRequest)
      {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else if (this.state.sortColumn) 
      {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else
    {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }
    this.setState(
        {sortRequestManagesLoading:true}
    )

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), requestsUrl);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

handleRequestsApiResponce = (responseJson: RequestResponseJson) => {
  
    if ("message" in responseJson && responseJson.message === "you are not authorized") {
      this.setState({ requestsLoading: false });
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error");
  } else {
      this.setState({ requestsLoading: false });
  
      if (responseJson) {
              this.setState({ allRequestsList: responseJson }, () => {
                  this.handleStatusJobModalClose();
              });
      }
  }
  
}
/* istanbul ignore next */ 
handleViewJob = (item : JobData) => {
  this.setState({isJOb:true, isRequest:false, selectedJob:item,jobAsscociateId:String(item.id)}, this.handlePopupClose)
}

handleViewRequest = (item : RequestType) => {
  this.setState({isJOb:false, isRequest:true, selectedRequest: item}, this.handlePopupClose)
  }
 //istanbul ignore next
  handleSelectedRequestStatus = (event: React.ChangeEvent<HTMLInputElement>,item:JobStatusData,requestStatusData:JobStatusData[]) => {
    if (event.target?.checked) {
      if (item.statusName === "all") {
        this.setState({
          checkedItemsRequest:requestStatusData.map((subItem) => subItem.statusName),
          status: "all"
        }, () => {
          this.sortRequestManage();
        })
      } else {
        this.setState({
          checkedItemsRequest: [...this.state.checkedItemsRequest, item.statusName],
          status: [...this.state.checkedItemsRequest,item.statusName ].join(",")
        }, () => {
          this.sortRequestManage();
        })
      }
    } else {
      if (item.statusName === "all") {
        this.setState({checkedItemsRequest: [], status: ""}, () => {
          this.sortRequestManage();
        })
      } else {
        this.setState({
          checkedItemsRequest: this.state.checkedItemsRequest.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
          status: this.state.checkedItemsRequest.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
        }, () => {
          this.sortRequestManage();
        })
      }
    }
  }
  //istanbul ignore next
  handleSelectStatusRequest = (item: {value:string, title: string; }) => {
    const { startDate, endDate } = this.state.selectedDates;
    this.setState({ dateStatus: item.title,selectedStatusForAPI:item.value }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.sortRequestManage();
        }
      } else {
        this.sortRequestManage();
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }
}


// Customizable Area End
